<template>
  <v-data-table
    ref="table"
    class="table-resizable sticky-header overflow-y-hidden max-height-100 flex-column"
    fixed-header
    :items="list"
    :hide-default-footer="hidePagination"
    :options="options"
    :loading="loading"
  >
    <template v-slot:header>
      <!-- PREPEND HEADER -->
      <th v-if="(prependActions && prependActions.some((action) => { return action.show })) || prependHeader" class="no-resizable">
        &nbsp;
      </th>
      <!-- AVAILABLE HEADERS -->
      <th v-for="(header, i) in displayHeaders" :key="i"
        @click="!!header.sortable ? $emit('changeSort', header.path) : null"
        class="header pa-4 text-xs-left caption font-weight-bold grey--text text--darken-2"
        :class="{
          'sortable': header.sortable,
          'desc': serverside ? serversideSorting.descending : pagination.descending,
          'asc': serverside ? !serversideSorting.descending : !pagination.descending,
          'active': header.path === (serverside ? serversideSorting.sortBy : pagination.sortBy)
        }"
      >
        <v-layout>
          <span>{{ header.text }}</span>
          <v-icon small v-if="header.sortable">mdi-menu-down</v-icon>
        </v-layout>
      </th>
      <!-- HEADER SELECTOR -->
      <th class="no-resizable">
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          offset-y
          :max-height="200"
        >
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-view-column</v-icon>
          </template>
          <v-card class="pa-2">
            <draggable :list="headers" @start="drag=true" @end="drag=false" :onEnd="dragReorder" handle=".handle">
              <div v-for="(header, i) in headers" :key="i">
                <v-tooltip :disabled="!header.disabled" nudge-top="10px" open-delay="400" bottom class="mx-1" style="display: flex;" >
                  <template v-slot:activator="{ on }">
                    <div style="display: flex;" v-on="on">
                      <v-checkbox v-if="!header.disabled" class="ma-0 pa-1" color="primary" hide-details v-model="header.show" :disabled="header.disabled" :label="header.text" />
                      <v-checkbox v-else class="ma-0 pa-1" color="primary" hide-details disabled :label="header.text" />
                      <v-spacer @click="header.show = !header.show" />
                      <v-icon class="flex-none handle">mdi-drag-horizontal-variant</v-icon>
                    </div>
                  </template>
                  <div v-if="header.disabled">{{ header.permission && getResourceName(header.permission)
                    ? $t('default_content.disabled_header_permission', { resource: getResourceName(header.permission) })
                    : header.tooltipText
                      ? header.tooltipText
                      : $t('default_content.disabled_header')
                  }}</div>
                </v-tooltip>
              </div>
            </draggable>
          </v-card>
        </v-menu>
      </th>
    </template>
    <template v-slot:item="{ item }">
      <tr>
        <!-- PREPEND ACTIONS -->
        <slot name="prependActions" :item="item" />
        <td class="no-resizable" v-if="prependActions && prependActions.some((action) => { return action.show })">
          <template v-for="(action) in prependActions">
            <v-tooltip
              v-if="action.show && (!isUnavailable(action.unavailable, item) || isDisabled(action.unavailable, item))"
              :disabled="!getText(action, item)"
              nudge-top="10px"
              open-delay="400"
              max-width="300"
              bottom
              :key="JSON.stringify(action)"
              class="mx-1">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon
                  :class="{'not-allowed': isDisabled(action.unavailable, item)}"
                  @click="actionClick(action, item)"
                >
                  <v-icon
                    :class="getActionClass(action, item)"
                    class="body-2 mx-1">
                    {{action.icon}}
                  </v-icon>
                </v-btn>
              </template>
              <div>{{ getText(action, item) }}</div>
            </v-tooltip>
            <v-tooltip v-else-if="action.show && (action.unavailable && item[action.unavailable.path] === action.unavailable.value) && action.unavailable.alternate"
              :disabled="!getText(action, item)" nudge-top="10px" open-delay="400" max-width="300" bottom :key="JSON.stringify(action)" class="mx-1">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="actionClick(action, item)" style="flex: none">
                  <v-icon class="body-2 mx-1">{{action.unavailable.alternate.icon}}</v-icon>
                </v-btn>
              </template>
              <div>{{action.unavailable.alternate.text}}</div>
            </v-tooltip>
          </template>
        </td>
        <!-- FIELDS -->
        <slot name="itemFields" :item="item" />
        <!-- APPEND ACTIONS -->
        <slot name="prependActions" :item="item" />
        <td class="no-resizable text-xs-center" :class="customStyle
          ? customStyle.appendActions || (customStyle.appendActionsFunc ? customStyle.appendActionsFunc(item) : null)
          : null">
          <slot name="appendActions" :item="item">
            <template v-for="(action) in appendActions">
              <v-progress-circular v-if="isLoading(action, item)" size="22" indeterminate color="primary" :key="JSON.stringify(action)"/>
              <v-tooltip
                v-else-if="isShown(action, item) && (!isUnavailable(action.unavailable, item) || isDisabled(action.unavailable, item))"
                :disabled="!getText(action, item)"
                nudge-top="10px"
                open-delay="400"
                max-width="300"
                bottom
                :key="JSON.stringify(action)"
                class="mx-1">
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    icon
                    @click="actionClick(action, item)"
                    :class="{'not-allowed': isDisabled(action.unavailable, item)}">
                    <v-icon
                      :class="`${$vuetify.breakpoint.lgAndUp ? 'hidden-table-action' : ''} ${action.class}`"
                      size="22">
                      {{action.icon}}
                    </v-icon>
                  </v-btn>
                </template>
                <div>{{ getText(action, item) }}</div>
              </v-tooltip>
              <v-tooltip v-else-if="isShown(action, item) && (action.unavailable && item[action.unavailable.path] === action.unavailable.value) && action.unavailable.alternate" :disabled="!getText(action, item)" nudge-top="10px" open-delay="400" max-width="300" bottom :key="JSON.stringify(action)" class="mx-1">
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                    @click="actionClick(action, item)"
                    :class="{'not-allowed': isDisabled(action.unavailable, item)}">
                    <v-icon :class="{'hidden-table-action': $vuetify.breakpoint.lgAndUp }" size="22">{{action.unavailable.alternate.icon}}</v-icon>
                  </v-btn>
                </template>
                <div>{{action.unavailable.alternate.text}}</div>
              </v-tooltip>
            </template>
          </slot>
        </td>
      </tr>
    </template>
    <template v-slot:no-data>
      <v-progress-circular v-if="loading" indeterminate color="primary darken-1"/>
      <span v-else>{{ $t('pagination.no_data') }}</span>
    </template>
    <template v-slot:loading>
      <v-progress-circular indeterminate color="primary darken-1"/>
    </template>
  </v-data-table>
</template>

<script>
import premissionMixins from '@/mixins/Permission'
import draggable from 'vuedraggable'

export default {
  props: {
    prependActions: Array,
    displayHeaders: Array,
    headers: Array,
    list: Array,
    pagination: Object,
    customStyle: Object,
    appendActions: Array,
    serverside: Boolean,
    prependHeader: Boolean,
    hidePagination: Boolean,
    loading: Boolean,
    itemsPerPageOptions: Array,
    options: Object,
    serversideSorting: Object
  },
  data () {
    return {
      menu: false
    }
  },
  methods: {
    actionClick (action, item) {
      if (this.isDisabled(action.unavailable, item)) {
        return
      }
      if (action.confirmation) {
        this.openConfirmModal(action, item)
      } else {
        this.$emit('appendAction', action.action, item)
      }
    },
    isShown (action, item) {
      return typeof action.show === 'boolean'
        ? action.show
        : typeof action.show === 'function'
          ? action.show(item)
          : false
    },
    isLoading (action, item) {
      return typeof action.loading === 'boolean'
        ? action.loading
        : typeof action.loading === 'function'
          ? action.loading(item)
          : false
    },
    isDisabled (unavailable, item) {
      return unavailable && (
          (unavailable.disable && this.isUnavailable(unavailable, item)) ||
          (unavailable.disableFunction ? unavailable.disableFunction(item) : false)
        )
    },
    isUnavailable (unavailable, item) {
      return !unavailable
        ? false
        : unavailable.function
          ? unavailable.function(item)
          : unavailable.path
            ? (item[unavailable.path] === unavailable.value)
            : false
    },
    getActionClass (action, object) {
      return (action.activeClass && (this.$parent.getObjectValue(object, { path: action.activeValue.path }) === action.activeValue.value))
        ? action.activeClass
        : ''
    },
    dragReorder ({ oldIndex, newIndex }) {
      this.$emit('dragReorder', oldIndex, newIndex)
    },
    getText (action, item) {
      return action.getText ? action.getText(item) : action.text
    }
  },
  mixins: [
    premissionMixins
  ],
  components: {
    draggable,
    LoadingField: () => import('@/components/app-components/LoadingField.vue')
  }
}
</script>

<style lang="scss" scoped>
.handle {
  cursor: move;
}
.header {
  & > div {
    max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  & span {
    text-align: left;    
    text-overflow: ellipsis;
    overflow: hidden; 
  }
  & .sortable {
    cursor: pointer;
    & .v-icon {
      visibility: hidden;
    }
    & :hover {
      color: var(--v-secondary-darken4);
      .v-icon {
        visibility: visible;
      }
    }
  }
}
::v-deep {
  .sortable {
    &.active .v-icon {
      color: rgba(0, 0, 0, 0.8);
    }
    &.desc .v-icon {
      transform: rotate(180deg);
    }
    & .v-icon {
      color: rgba(0, 0, 0, 0.4);
    }
    &:not(.active):not(:hover) .v-icon {
      opacity: 0;
    }
  }
}
</style>
