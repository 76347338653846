var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{ref:"table",staticClass:"table-resizable sticky-header overflow-y-hidden max-height-100 flex-column",attrs:{"fixed-header":"","items":_vm.list,"hide-default-footer":_vm.hidePagination,"options":_vm.options,"loading":_vm.loading},scopedSlots:_vm._u([{key:"header",fn:function(){return [((_vm.prependActions && _vm.prependActions.some(function (action) { return action.show })) || _vm.prependHeader)?_c('th',{staticClass:"no-resizable"}):_vm._e(),_vm._l((_vm.displayHeaders),function(header,i){return _c('th',{key:i,staticClass:"header pa-4 text-xs-left caption font-weight-bold grey--text text--darken-2",class:{
        'sortable': header.sortable,
        'desc': _vm.serverside ? _vm.serversideSorting.descending : _vm.pagination.descending,
        'asc': _vm.serverside ? !_vm.serversideSorting.descending : !_vm.pagination.descending,
        'active': header.path === (_vm.serverside ? _vm.serversideSorting.sortBy : _vm.pagination.sortBy)
      },on:{"click":function($event){!!header.sortable ? _vm.$emit('changeSort', header.path) : null}}},[_c('v-layout',[_c('span',[_vm._v(_vm._s(header.text))]),(header.sortable)?_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-menu-down")]):_vm._e()],1)],1)}),_c('th',{staticClass:"no-resizable"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","max-height":200},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("mdi-view-column")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"pa-2"},[_c('draggable',{attrs:{"list":_vm.headers,"onEnd":_vm.dragReorder,"handle":".handle"},on:{"start":function($event){_vm.drag=true},"end":function($event){_vm.drag=false}}},_vm._l((_vm.headers),function(header,i){return _c('div',{key:i},[_c('v-tooltip',{staticClass:"mx-1",staticStyle:{"display":"flex"},attrs:{"disabled":!header.disabled,"nudge-top":"10px","open-delay":"400","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('div',_vm._g({staticStyle:{"display":"flex"}},on),[(!header.disabled)?_c('v-checkbox',{staticClass:"ma-0 pa-1",attrs:{"color":"primary","hide-details":"","disabled":header.disabled,"label":header.text},model:{value:(header.show),callback:function ($$v) {_vm.$set(header, "show", $$v)},expression:"header.show"}}):_c('v-checkbox',{staticClass:"ma-0 pa-1",attrs:{"color":"primary","hide-details":"","disabled":"","label":header.text}}),_c('v-spacer',{on:{"click":function($event){header.show = !header.show}}}),_c('v-icon',{staticClass:"flex-none handle"},[_vm._v("mdi-drag-horizontal-variant")])],1)]}}],null,true)},[(header.disabled)?_c('div',[_vm._v(_vm._s(header.permission && _vm.getResourceName(header.permission) ? _vm.$t('default_content.disabled_header_permission', { resource: _vm.getResourceName(header.permission) }) : header.tooltipText ? header.tooltipText : _vm.$t('default_content.disabled_header')))]):_vm._e()])],1)}),0)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',[_vm._t("prependActions",null,{"item":item}),(_vm.prependActions && _vm.prependActions.some(function (action) { return action.show }))?_c('td',{staticClass:"no-resizable"},[_vm._l((_vm.prependActions),function(action){return [(action.show && (!_vm.isUnavailable(action.unavailable, item) || _vm.isDisabled(action.unavailable, item)))?_c('v-tooltip',{key:JSON.stringify(action),staticClass:"mx-1",attrs:{"disabled":!_vm.getText(action, item),"nudge-top":"10px","open-delay":"400","max-width":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({class:{'not-allowed': _vm.isDisabled(action.unavailable, item)},attrs:{"icon":""},on:{"click":function($event){return _vm.actionClick(action, item)}}},on),[_c('v-icon',{staticClass:"body-2 mx-1",class:_vm.getActionClass(action, item)},[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.getText(action, item)))])]):(action.show && (action.unavailable && item[action.unavailable.path] === action.unavailable.value) && action.unavailable.alternate)?_c('v-tooltip',{key:JSON.stringify(action),staticClass:"mx-1",attrs:{"disabled":!_vm.getText(action, item),"nudge-top":"10px","open-delay":"400","max-width":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
return [_c('v-btn',_vm._g({staticStyle:{"flex":"none"},attrs:{"icon":""},on:{"click":function($event){return _vm.actionClick(action, item)}}},on),[_c('v-icon',{staticClass:"body-2 mx-1"},[_vm._v(_vm._s(action.unavailable.alternate.icon))])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(action.unavailable.alternate.text))])]):_vm._e()]})],2):_vm._e(),_vm._t("itemFields",null,{"item":item}),_vm._t("prependActions",null,{"item":item}),_c('td',{staticClass:"no-resizable text-xs-center",class:_vm.customStyle
        ? _vm.customStyle.appendActions || (_vm.customStyle.appendActionsFunc ? _vm.customStyle.appendActionsFunc(item) : null)
        : null},[_vm._t("appendActions",[_vm._l((_vm.appendActions),function(action){return [(_vm.isLoading(action, item))?_c('v-progress-circular',{key:JSON.stringify(action),attrs:{"size":"22","indeterminate":"","color":"primary"}}):(_vm.isShown(action, item) && (!_vm.isUnavailable(action.unavailable, item) || _vm.isDisabled(action.unavailable, item)))?_c('v-tooltip',{key:JSON.stringify(action),staticClass:"mx-1",attrs:{"disabled":!_vm.getText(action, item),"nudge-top":"10px","open-delay":"400","max-width":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({class:{'not-allowed': _vm.isDisabled(action.unavailable, item)},attrs:{"icon":""},on:{"click":function($event){return _vm.actionClick(action, item)}}},on),[_c('v-icon',{class:((_vm.$vuetify.breakpoint.lgAndUp ? 'hidden-table-action' : '') + " " + (action.class)),attrs:{"size":"22"}},[_vm._v(" "+_vm._s(action.icon)+" ")])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(_vm.getText(action, item)))])]):(_vm.isShown(action, item) && (action.unavailable && item[action.unavailable.path] === action.unavailable.value) && action.unavailable.alternate)?_c('v-tooltip',{key:JSON.stringify(action),staticClass:"mx-1",attrs:{"disabled":!_vm.getText(action, item),"nudge-top":"10px","open-delay":"400","max-width":"300","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({class:{'not-allowed': _vm.isDisabled(action.unavailable, item)},attrs:{"icon":""},on:{"click":function($event){return _vm.actionClick(action, item)}}},on),[_c('v-icon',{class:{'hidden-table-action': _vm.$vuetify.breakpoint.lgAndUp },attrs:{"size":"22"}},[_vm._v(_vm._s(action.unavailable.alternate.icon))])],1)]}}],null,true)},[_c('div',[_vm._v(_vm._s(action.unavailable.alternate.text))])]):_vm._e()]})],{"item":item})],2)],2)]}},{key:"no-data",fn:function(){return [(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary darken-1"}}):_c('span',[_vm._v(_vm._s(_vm.$t('pagination.no_data')))])]},proxy:true},{key:"loading",fn:function(){return [_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary darken-1"}})]},proxy:true}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }